<script>
import Multiselect from "vue-multiselect";
import widget from "./widget.vue";
import composicao from "./composicao.vue";
export default {
  props: {
    empresa: { type: Object, required: true },
    ibge: { type: Array },
    produtos2: { type: Array },
    locais_estoques: { type: Array },
  },
  components: { Multiselect, widget, composicao },
  data() {
    return {
      compoe_produtos_vet:[],
      select_procesado: null,
      l_estoques: [],
      titleBody: "Produto Confecção",
      estados: [],
      estados2: [],
      list_produtos: [],
      items: ["produto-1", "produto-2", "produto-3"],
      product_confec: {
        number_order: 2,
        filial_id: 1,
        cod: 2,
        reference: "ola",
        name: "produto",
        in_stock: "2",
        amount: "45",
        forecast: "2023-06-30T08:43", // previsão
        processed: 1,
        stock_destiny: 1,
        value: 50.0,
        date_destiny: "2023-06-30T08:43",
        alter_last: "alterado",
        user_cod: 2,
      },
      name: null,
      processados: [
        {
          id: 1,
          nome: "sim",
        },
        {
          id: 0,
          nome: "nao",
        },
      ],
      objeto_widget: null,
      select_produto: null,
      confecao: {
        codigo: null,
        referencia: null,
        produto_id: null,
        quantidade: null,
        dt_conclusao: null,
        processado_id: null,
        local_estoque_id: null,
        dt_cadastro: null,
        ultima_alt: null,
        cod_user: null,
        descricao: null,
      },
      modal_abr: false,
      composicoes: [],
      exibir_composicao: false,
      estoque_produto: null,
      consumo: null,
      estoques_vetor: [],
      estoques_vetor2: [],
    };
  },
  created() {
    this.list_produtos = this.produtos2;
    this.l_estoques = this.locais_estoques;
  },
  mounted() {
    this.$emit("newTitle", this.titleBody);
    //console.log(this.ibge);
    //this.convert_citys();
    this.list_produtos = this.produtos2;
    console.log("estoques");
    console.log(this.l_estoques);
  },

  methods: {
    calcular_consumo(event) {
      console.log("estou no calcular conssumo");
      let quantidade = event.target.value;
      console.log("quantidade");
      console.log(quantidade);
      console.log("estoque Prod");
      //console.log(this.estoque_produto)
      //   é a quantidade vezes a quantidade de produto composião necessario;
      //  * cada produto da composição , que no caso é esse arrya .
      // this.composicoes
      this.consumo = quantidade * this.estoque_produto;
      console.log("Consumo");
      console.log(this.consumo);

      //  let estoque_prod =this.estoque_produto

      //  if(estoque_prod.includes('.') && estoque_prod.includes(',') ){
      //   estoque_prod = parseFloat(estoque_prod.replace('.','').replace(',','.'))
      //  }else if((!estoque_prod.includes('.')) && estoque_prod.includes(',')){
      //   estoque_prod = parseFloat(estoque_prod.replace(',','.'))
      //  }else {
      //   estoque_prod =this.estoque_produto
      //  }

      //  this.consumo = estoque_prod * quantidade;
      //  console.log(this.consumo)
    },
    seleciona_processado(event) {
      this.confecao.processado_id = event.target.value;
      console.log(this.confecao.processado_id);
    },
    remove_produto(event) {
      console.log("estou em remover");
      console.log(event);
      this.select_produto = null;
      this.estoque_produto = null;
    },
    seleciona_produto(event) {
      // console.log(event);

      // esse vetor vai ser usado para fazer um novo fitros , quando for selecionado o local de estoque
      this.estoques_vetor = event.estoque;
      //
      if (event.estoque.length > 0) {
        let array_estoque_pruduto = event.estoque;
        let tam_array_estoque_prod = array_estoque_pruduto.length;
        //  tenho que filttrar esse estoque , com a locaL_estoque_id tb,
        this.estoque_produto =
          array_estoque_pruduto[tam_array_estoque_prod - 1].qtdenew;
      }

      this.confecao.produto_id = event.id;
      this.confecao.descricao = event.produto_nome;
      this.compoe_produtos_vet = event.compoe_produtos
      if (event.compoe_produtos != null) {
        this.exibir_composicao = true;
        this.composicoes = event.compoe_produtos;
        //  console.log("estou no selecionar produtos ")
        //  console.log(this.composicoes)
      } else {
        this.exibir_composicao = false;
      }
    },
    selecionando_name(objeto) {
      this.product_confec.name = objeto.produto_nome;
      console.log(this.product_confec.name);
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },

    setPost() {
      //console.log(this.cidades);

        if(this.compoe_produtos_vet.length > 0){
          console.log("objeto do insret");
        console.log(this.confecao);
        this.$emit("doPost", this.confecao);
        }else {
          this.makeToast('info', 'este produto não pdoe ser produzido pois  é uma materia prima')
        }
        
     
    },
    
    opcaoSelecionada(event) {
      // console.log(event.target.value);
      let id_select = event.target.value;
      this.confecao.local_estoque_id = event.target.value;
      let objeto_selecionado = this.l_estoques.find((p) => p.id == id_select);
      this.objeto_widget = objeto_selecionado;
      if (event.target.value == -1) {
        this.objeto_widget = null;
      }
      //console.log(this.objeto_widget);

      let local_estoque_id = event.target.value; // usado no if
      if (this.estoques_vetor.length > 0) {
        /// console.log(local_estoque_id);
        let vetor = this.estoques_vetor;
        let vazio = [];
        vetor.forEach((item, index) => {
          let iten_local_id = item.local_estoque_id;
          if (iten_local_id == local_estoque_id) {
            this.estoques_vetor2 = vazio;
            this.estoques_vetor2[index] = item;
          }
        });
      }
      let new_tam = this.estoques_vetor2.length;
      let new_qtd_valor = this.estoques_vetor[new_tam - 1];
      console.log("valor objeto");
      console.log(new_qtd_valor.qtdenew);

      this.estoque_produto = new_qtd_valor.qtdenew;
      console.log("valor Array");
      console.log(this.estoques_vetor2);
    },

    modal_composicao() {
      this.modal_abr = true;
    },
    back() {
      this.modal_abr = false;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <widget
            :objeto="objeto_widget"
            :estoque_produto="estoque_produto"
            :consumo="consumo"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div :class="exibir_composicao == true ? 'col-md-3' : 'col-md-4'">
          <Label>
            <b-badge
              data-name="credito"
              class="field-status"
              :variant="confecao.codigo == null ? 'danger' : 'success'"
              >{{ "*Código" }}</b-badge
            ></Label
          >
          <input
            v-model="confecao.codigo"
            type="number"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div :class="exibir_composicao == true ? 'col-md-3' : 'col-md-4'">
          <Label
            ><b-badge
              data-name="credito"
              class="field-status"
              :variant="confecao.referencia == null ? 'danger' : 'success'"
              >{{ "*Referência" }}</b-badge
            ></Label
          >

          <input
            v-model="confecao.referencia"
            type="number"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div :class="exibir_composicao == true ? 'col-md-4' : 'col-md-4'">
          <Label
            ><b-badge
              data-name="credito"
              class="field-status"
              :variant="select_produto == null ? 'danger' : 'success'"
              >{{ "*Nome do Produto" }}</b-badge
            ></Label
          >

          <multiselect
            @select="seleciona_produto($event)"
            @remove="remove_produto($event)"
            v-model="select_produto"
            :options="list_produtos"
            label="produto_nome"
          >
          </multiselect>
        </div>
        <div class="col-md-2 mt-1" v-if="exibir_composicao">
          <button
            class="btn btn-secondary mt-4"
            @click.prevent="modal_composicao()"
          >
            Composição
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" v-if="modal_abr">
          <composicao
            :abrir_modal="modal_abr"
            @back="back"
            :composicoes="composicoes"
          >
          </composicao>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-3">
          <Label
            ><b-badge
              data-name="credito"
              class="field-status"
              :variant="confecao.quantidade == null ? 'danger' : 'success'"
              >{{ "*Quantidade" }}</b-badge
            ></Label
          >

          <input
            v-model="confecao.quantidade"
            type="number"
            class="form-control text-left"
            placeholder="Digite"
            min="1"
            @input="calcular_consumo($event)"
          />
        </div>
        <div class="col-md-3">
          <Label
            ><b-badge
              data-name="credito"
              class="field-status"
              :variant="confecao.dt_conclusao == null ? 'danger' : 'success'"
              >{{ "*Previsão de Conclusão" }}</b-badge
            ></Label
          >

          <input
            v-model="confecao.dt_conclusao"
            type="date"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div class="col-md-3">
          <Label
            ><b-badge
              data-name="credito"
              class="field-status"
              :variant="select_procesado == null ? 'danger' : 'success'"
              >{{ "*Procesado" }}</b-badge
            ></Label
          >
          <!-- @change="opcaoSelecionada($event)" -->

          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="select_procesado"
            @change="seleciona_processado($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in processados"
              :key="index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <Label
            ><b-badge
              data-name="credito"
              class="field-status"
              :variant="
                confecao.local_estoque_id == null ? 'danger' : 'success'
              "
              >{{ "*Local de Estoque" }}</b-badge
            ></Label
          >

          <select
            class="form-control"
            id="exampleFormControlSelect1"
            @change="opcaoSelecionada($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in l_estoques"
              :key="index"
              :value="item.id"
            >
              {{ item.descricao }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4">
          <Label
            ><b-badge
              data-name="credito"
              class="field-status"
              :variant="confecao.dt_cadastro == null ? 'danger' : 'success'"
              >{{ "*Data Cadastro" }}</b-badge
            ></Label
          >

          <input
            v-model="confecao.dt_cadastro"
            type="date"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-4">
          <Label>Ultima Alteração</Label>
          <input
            v-model="confecao.ultima_alt"
            type="text"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div class="col-md-4">
          <Label>Cod Usúario</Label>
          <input
            v-model="confecao.cod_user"
            type="number"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-secondary" @click.prevent="setPost()">
            inserir
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>