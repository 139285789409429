<script>
export default {
  props: {
    // pelo nome  e id do objeto fazer uma pesquisa e armazenar a respota em uma vetor 
    // para colocar no foreach da tabela;
    produto_confec: { type: Object },
    array_produtos: { type: Array },
    produtosGeral: { type: Array },
  },
  data() {
    return {

     
      fieldsComposisoes: [
        // {
        //   label: "Código",
        //   key: "id",
        //   sortable: true,
        //   tdClass: "text-right",
        //   thClass: "text-center",
        // },
        {
          label: "Código Produto",
          key: "produto_id",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Nome do Produto",
          key: "produto_nome",
          sortable: true,
          thClass: "text-center",
        },
        {
          label: "Unidade",
          key: "un",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Custo R$",
          key: "custo",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: "formatterCurrBR",
        },
        {
          label: "Preço R$",
          key: "venda",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: "formatterCurrBR",
        },
        {
          label: "Quantidade",
          key: "quantidade",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Ações",
        //   key: "acoes",
        //   sortable: false,
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
      ],
      array_compoe: [],
      produtos:[],
      obj_prod_confe : null,
      //composicoes:[]
    }
  },
  created(){
    this.produtos = this.produtosGeral
    this.obj_prod_confe = this.produto_confec
  },
  mounted() {
    console.log("estou no detail")
    console.log(this.produtos)

    this.selecinar_objeto_produtos()
    
  },
  methods:{
    // usei para pesquisa o elemtento pelo id do produto para mostar os arrya de composição desse t
    // determinado produto
    selecinar_objeto_produtos(){
        let seleciona_prod =  this.produtos.find((p)=> p.id == this.obj_prod_confe.produto_id)
        console.log("estuo no selcioanr de detail")
        console.log(seleciona_prod)
         this.array_compoe = seleciona_prod.compoe_produtos
    }
  }
}
</script>

<template>
  <!-- <div class="Produto_confec">
  <p>ID: {{ produto_confec.id }}</p>
  <p>Nome: {{ produto_confec.produto_nome }}</p>
  <p>Usuário: {{ produto_confec.estado }}</p>
  <p>Ativo: <b-badge data-name="status" class="field-status" v-bind:variant="(produto_confec.status) ? 'success' : 'danger'">{{ (produto_confec.status) ? 'Sim' : 'Não' }}</b-badge></p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ produto_confec.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ produto_confec.updated_at }}</b-badge></p>
</div> -->

  <div>
    <b-table
        :items="array_compoe"
        :fields="fieldsComposisoes"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :hover="true"
      >
      </b-table>

  </div>
</template>