<template>
  <b-modal
    v-model="modal_show"
    id="modal-xl"
    size="xl"
    title="Composição do Produto"
    hide-footer
    @hidden="back()"
  >
    <div>
      <H1>Composição de produtos</H1>
      <b-table
        :items="array_compoe"
        :fields="fieldsComposisoes"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :hover="true"
      >
      </b-table>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    composicoes: { type: Array },
    abrir_modal: { type: Boolean },
  },

  data() {
    return {
      array_compoe: [],
      modal_show: false,
      fieldsComposisoes: [
        // {
        //   label: "Código",
        //   key: "id",
        //   sortable: true,
        //   tdClass: "text-right",
        //   thClass: "text-center",
        // },
        {
          label: "Código Produto",
          key: "produto_id",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Nome do Produto",
          key: "produto_nome",
          sortable: true,
          thClass: "text-center",
        },
        {
          label: "Unidade",
          key: "un",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Custo R$",
          key: "custo",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: "formatterCurrBR",
        },
        {
          label: "Preço R$",
          key: "venda",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: "formatterCurrBR",
        },
        {
          label: "Quantidade",
          key: "quantidade",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },

  created() {
    this.modal_show = this.abrir_modal;
    this.array_compoe = this.composicoes
  },
  mounted(){
     console.log("estou no array compoe")
     console.log(this.array_compoe)
  },
  methods: {
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style>
</style>