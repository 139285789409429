<script>
import Multiselect from "vue-multiselect";
import widget from "./widget.vue";
export default {
  props: {
    empresa: { type: Object, required: true },
    oldConfecao: { type: Object, required: true },
    locais_estoques: { type: Array },
    produtos2: { type: Array },
  },
  components: { Multiselect, widget },
  data() {
    return {
      titleBody: "Alterar Produto Confecção",
      product_confec: {
        number_order: 2,
        filial_id: 1,
        cod: 2,
        reference: "ola",
        name: "produto",
        in_stock: "2",
        amount: "45",
        forecast: "2023-06-30T08:43", // previsão
        processed: 1,
        stock_destiny: 1,
        value: 50.0,
        date_destiny: "2023-06-30T08:43",
        alter_last: "alterado",
        user_cod: 2,
      },
      items: ["item 1", "item 2", "item 3"],
      confecao: {
        codigo: null,
        referencia: null,
        produto_id: null,
        quantidade: null,
        dt_conclusao: null,
        processado_id: null,
        local_estoque_id: null,
        dt_cadastro: null,
        ultima_alt: null,
        cod_user: null,
      },
      objeto_widget: null,
      select_produto: null,
      l_estoques: [],
      list_produtos: [],
      processados: [
        {
          id: 1,
          nome: "sim",
        },
        {
          id: 0,
          nome: "nao",
        },
      ],

      select_procesado:null,
      select_local:null
    };
  },
  created() {
    this.confecao = this.oldConfecao;
    this.l_estoques = this.locais_estoques;
    this.list_produtos = this.produtos2;

  },
  mounted() {
    this.$emit("newTitle", this.titleBody);
    console.log(this.confecao);
    this.inicializar_valores()
  },
  methods: {
    inicializar_valores() {
      // processado_id
      let objeto_selecionado = this.processados.find(
        (p) => p.id == this.confecao.processado_id
      );
     // console.log(objeto_selecionado);
      this.select_procesado = objeto_selecionado.id

      let produto_selecionado = this.list_produtos.find((p)=>p.id == this.confecao.produto_id)
      //console.log(produto_selecionado)
      this.select_produto = produto_selecionado

      this.select_local = this.confecao.local_estoque_id
    },
    setPut() {
      this.$emit("doPut", this.confecao);
    },
    opcaoSelecionada(event) {
      console.log(event.target.value);
      let id_select = event.target.value;
      this.confecao.local_estoque_id = event.target.value;
      let objeto_selecionado = this.l_estoques.find((p) => p.id == id_select);
      this.objeto_widget = objeto_selecionado;
      if (event.target.value == -1) {
        this.objeto_widget = null;
      }
      //console.log(this.objeto_widget);
    },
    seleciona_processado(event) {
      this.confecao.processado_id = event.target.value;
      console.log(this.confecao.processado_id);
    },
    seleciona_produto(event) {
      this.confecao.produto_id = event.id;
      this.confecao.descricao = event.produto_nome;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <widget :objeto="objeto_widget" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4">
          <Label>Código</Label>
          <input
            v-model="confecao.codigo"
            type="number"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div class="col-md-4">
          <Label>Referência</Label>
          <input
            v-model="confecao.referencia"
            type="number"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div class="col-md-4">
          <Label>Nome do Produto</Label>
          <multiselect
            @select="seleciona_produto($event)"
            v-model="select_produto"
            :options="list_produtos"
            label="produto_nome"
          >
          </multiselect>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-3">
          <Label>Quantidade</Label>
          <input
            v-model="confecao.quantidade"
            type="number"
            class="form-control text-left"
            placeholder="Digite"
            min="1"
          />
        </div>
        <div class="col-md-3">
          <Label>Previsão de Conclusão</Label>
          <input
            v-model="confecao.dt_conclusao"
            type="date"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div class="col-md-3">
          <Label>Procesado</Label>
          <!-- @change="opcaoSelecionada($event)" -->
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="select_procesado"
            @change="seleciona_processado($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in processados"
              :key="index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <Label>{{ "*Local de Estoque" }}</Label>
          <select
            v-model="select_local"
            class="form-control"
            id="exampleFormControlSelect1"
            @change="opcaoSelecionada($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in l_estoques"
              :key="index"
              :value="item.id"
            >
              {{ item.descricao }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4">
          <Label>Data Cadastro</Label>
          <input
            v-model="confecao.dt_cadastro"
            type="date"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-4">
          <Label>Ultima Alteração</Label>
          <input
            v-model="confecao.ultima_alt"
            type="text"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
        <div class="col-md-4">
          <Label>Cod Usúario</Label>
          <input
            v-model="confecao.cod_user"
            type="number"
            class="form-control text-left"
            placeholder="Digite"
          />
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-secondary" @click.prevent="setPut()">
            Alterar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>