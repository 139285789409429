<script>
/**
 * Widget Component
 */
export default {
  props: {
    objeto: { type: Object },
    estoque_produto: { type: Number },
    consumo: { type: Number },
  },
  data() {
    return {
      statData: [
        {
          title: "identificador",
          icon: "ri-stack-line",
          value: "Selecione",
          subvalue: "",
        },
        {
          title: "Local do Estoque",
          icon: "ri-store-2-line",
          value: "Selecione",
          subvalue: "",
        },
        {
          title: "Quantidade",
          icon: "ri-briefcase-4-line",
          value: "0,00",
          subvalue: "",
        },
        {
          title: "Cosumo",
          icon: "ri-briefcase-4-line",
          value: "0,00",
          subvalue: "",
        },
      ],
      qtde_estoque:null
    };
  },
  mounted(){
    //   console.log("estou no estoque vendo os valor de estoque")
    //  console.log(this.estoque_produto);
  },
  watch: {
    estoque_produto(novoValor) {
      this.statData[2].value = novoValor;
      this.statData[2].subvalue = novoValor;
    },
    consumo(novoPagamento) {
      this.statData[3].value = novoPagamento;
      this.statData[3].subvalue = novoPagamento;
    },

    objeto: {
      handler(novoObjeto, antigoObjeto) {
        // Aqui você pode fazer o que quiser com a nova propriedade 'objeto'
        console.log(antigoObjeto);
        console.log('Novo valor da propriedade "objeto":', novoObjeto);
        if (novoObjeto == null) {
          this.statData[1].value = "Selecione";
          this.statData[0].value = "Selecione";
        } else {
          this.statData[1].value = novoObjeto.descricao;
          this.statData[0].value = novoObjeto.id;
        }
      },
      deep: true, // Este observador é profundo, ou seja, observa alterações em objetos aninhados
    },
  },
};
// ola
</script>
<template>
  <div class="row">
    <div class="col-md-3" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0">{{ data.value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{ data.subvalue }}
            </span>
            <span class="text-muted ml-2">-Informação</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>